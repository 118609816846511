import request from '@/utils/request'

const prefix = '/api/profitShare'

export default {
  list(params) {
    return request.get(`${prefix}/list`, {params})
  },
  getTotalAmount() {
    return request.get(`${prefix}/totalAmount`)
  }
}
