<template>
  <div>
    <van-nav-bar :title="totalAmount" left-arrow @click-left="toUcenter" />
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="initDataList">
      <van-cell v-for="item in dataList" :key="item.id" :title="item.descn" :value="item.receiveAmount|formatMoney" :label="item.profitShareTime" />
    </van-list>
  </div>
</template>
<script>
import profitShareApi from '@/api/profit-share'
export default {
  data() {
    return {
      appId: '',
      searchKey: '',
      dataList: [],
      loading: false,
      finished: false,
      maxId: '',
      totalAmount: '合计：0.00 元'
    }
  },
  created() {
    this.appId = this.$route.params.appId
  },
  mounted() {
    this.getTotalAmount()
  },
  methods: {
    getTotalAmount() {
      profitShareApi.getTotalAmount().then(res => {
        if (res.succ) {
          this.totalAmount = '合计：' + res.data.item + ' 元'
        }
      })
    },
    initDataList() {
      profitShareApi.list({ maxId: this.maxId }).then(res => {
        if (res.succ) {
          let items = res.data.items
          if (items.length === 0) {
            this.finished = true
          } else {
            for (let i = 0; i < items.length; i++) {
              this.dataList.push(items[i])
            }
            this.loading = false
            this.maxId = items[items.length - 1].id
          }
        }
      })
    },
    handleSearch() {},
    toUcenter() {
      this.$router.push(`/${this.appId}/ucenter`)
    }
  }
}
</script>
<style lang="less" scoped>
</style>
